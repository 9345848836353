<template>
    <v-card color="white" class="elevation-2 fill-height mt-md-1">
        <v-card-text class="pa-0 pt-0 pt-0 pb-0 px-lg-2 secondary--text">
            <v-row no-gutters class="align-center">
                <v-col cols="12" lg="12" class="text-center" v-if="$vuetify.breakpoint.mdAndUp && user.image">
                    <v-avatar @click="$router.push('/app/account'); $appFn.trackEvent({ action: 'nav_event', options: { action_name: 'Dashboard: UserInfo: Photo Clicked' }});" class="mt-3 mt-lg-1 action-item elevation-3" :size="(user.image && !$vuetify.breakpoint.mdAndDown) ? 100 : 100">
                        <v-img class="img-border-primary elevation-3" v-if="user.image" :src="user.image" />
                        <v-icon v-else class="img-border-primary" x-large color="primary">fal fa-camera</v-icon>
                    </v-avatar>
                    <span class="caption d-inline-block d-lg-block ml-3 ml-lg-0 mt-2 action-item" @click="$router.push('/app/account')" v-if="!user.image">Add a Photo</span>
                </v-col>
                <v-col cols="12" lg="12" class="pb-0">
                    <v-list class="pt-0 pb-0 px-4 px-lg-0 no-truncate">
                        <v-list-item class="pl-0" @click="$router.push('/app/account'); $appFn.trackEvent({ action: 'nav_event', options: { action_name: 'Dashboard: UserInfo: Display Name Clicked' }});">
                            <v-list-item-avatar class="mr-2 mr-lg-3 mt-1">
                                <v-avatar>
                                    <v-icon class="pl-1" color="primary">fa fa-id-card</v-icon>
                                </v-avatar>
                            </v-list-item-avatar>
                            <v-list-item-content>
                                <v-list-item-title class="font-weight-medium secondary--text">{{ user.name }}</v-list-item-title>
                                <v-list-item-subtitle>Name displayed to contacts.</v-list-item-subtitle>
                            </v-list-item-content>
                            <v-list-item-action>
                                <v-icon small color="#999">fa fa-cog</v-icon>
                            </v-list-item-action>
                        </v-list-item>
                        <v-list-item class="pl-0" @click="$router.push('/app/account'); $appFn.trackEvent({ action: 'nav_event', options: { action_name: 'Dashboard: UserInfo: Phone Clicked' }});">
                            <v-list-item-avatar class="mr-2 mr-lg-3 mt-1">
                                <v-avatar>
                                    <v-icon class="pl-1" color="primary">fa fa-phone</v-icon>
                                </v-avatar>
                            </v-list-item-avatar>
                            <v-list-item-content>
                                <v-list-item-title class="font-weight-medium secondary--text">{{ user.phone }}</v-list-item-title>
                                <v-list-item-subtitle>Send check-ins to this number.</v-list-item-subtitle>
                            </v-list-item-content>
                            <v-list-item-action>
                                <v-icon small color="#999">fa fa-cog</v-icon>
                            </v-list-item-action>
                        </v-list-item>
                        <v-list-item class="pl-0" @click="$router.push('/app/account'); $appFn.trackEvent({ action: 'nav_event', options: { action_name: 'Dashboard: UserInfo: Timezone Clicked' }});">
                            <v-list-item-avatar class="mr-2 mr-lg-3 mt-1">
                                <v-avatar>
                                    <v-icon class="pl-1" color="primary">fa fa-clock</v-icon>
                                </v-avatar>
                            </v-list-item-avatar>
                            <v-list-item-content>
                                <v-list-item-title class="font-weight-medium secondary--text">Current Timezone: {{ thisTimezone }}</v-list-item-title>
                                <v-list-item-subtitle>Your local time is {{ $moment.tz(user.timezone).format('h:mma') }}.</v-list-item-subtitle>
                            </v-list-item-content>
                            <v-list-item-action>
                                <v-icon small color="#999">fa fa-cog</v-icon>
                            </v-list-item-action>
                        </v-list-item>
                        <v-list-item v-if="!user.image || $vuetify.breakpoint.smAndDown" class="pl-0" @click="$router.push('/app/account'); $appFn.trackEvent({ action: 'nav_event', options: { action_name: 'Dashboard: UserInfo: Photo Clicked' }});">
                            <v-list-item-avatar class="mr-2 mr-lg-3 mt-1">
                                <v-avatar>
                                    <v-icon class="pl-1" color="primary">fa fa-camera</v-icon>
                                </v-avatar>
                            </v-list-item-avatar>
                            <v-list-item-content>
                                <v-list-item-title class="font-weight-medium secondary--text">Add a Photo</v-list-item-title>
                                <v-list-item-subtitle>Displayed to emergency contacts.</v-list-item-subtitle>
                            </v-list-item-content>
                            <v-list-item-action>
                                <v-icon small color="#999">fa fa-cog</v-icon>
                            </v-list-item-action>
                        </v-list-item>
                    </v-list>
                    <!-- <p class="mb-0">
                        <span class="subtitle-1 mb-0 d-block font-weight-medium">{{ user.name }}</span>
                        <span class="caption grey--text">Named Displayed to Contacts</span>
                        <br>
                        <span class="subtitle-1">{{ user.phone }}</span>
                    </p> -->
                </v-col>
            </v-row>
            <v-list class="pt-0 pb-0 d-none transparent" v-show="!axn">
                <v-list-item class="pl-0" to="/app/account">
                    <v-list-item-avatar size="65" class="pr-0 mr-1">
                        <v-avatar>
                            <v-img class="img-border elevation-3" v-if="user.image" :src="user.image" />
                            <v-icon v-else class="img-border" color="primary">fal fa-camera</v-icon>
                        </v-avatar>
                    </v-list-item-avatar>
                    <v-list-item-content class="pl-0">
                        <v-list-item-title class="font-weight-medium secondary--text">{{ user.name }}</v-list-item-title>
                        <v-list-item-subtitle class="pt-0">Sending check-ins to {{ user.phone }}</v-list-item-subtitle>
                        <v-list-item-subtitle class="" style="padding-top: 1px;">Your local time is {{ $moment.tz(user.timezone).format('h:mma') }} {{ thisTimezone }}</v-list-item-subtitle>
                    </v-list-item-content>
                </v-list-item>
            </v-list>
        </v-card-text>
    </v-card>
</template>
<script>

import { mapState } from 'vuex'
import tzs from '@/data/timezones'

export default {

  name: 'UserDashboardUserInfo',

    props: {
    },
    computed: {
        ...mapState(['user'])
    },
    data: () => ({
        axn: null,
        thisTimezone: '',
        timezones: tzs.tzs
    }),
    methods: {
        initData(){
            this.getTimezone();
        },
        getTimezone(){
            for(let i in this.timezones){
                if(this.timezones[i].value == this.user.timezone){
                    this.thisTimezone = this.timezones[i].abbr; 
                }
            }
        },
    },
    mounted(){
        this.initData();
    },
    watch: {
    }
}
</script>
