export default{
    tzs: [
      {
         "value": 'America/New_York',
         "text": "(GMT -5:00) Eastern Time (US & Canada), Bogota, Lima",
         "abbr": "EST"
      },
      {
         "value": 'America/Detroit',
         "text": "(GMT -6:00) Central Time (US & Canada), Mexico City",
         "abbr": "CST"
      },
      {
         "value": 'America/Boise',
         "text": "(GMT -7:00) Mountain Time (US & Canada)",
         "abbr": "MST"
      },
      {
         "value": 'America/Los_Angeles',
         "text": "(GMT -8:00) Pacific Time (US & Canada)",
         "abbr": "PST"
      },
      {
         "value": 'Pacific/Honolulu',
         "text": "(GMT -10:00) Hawaii",
         "abbr": "HST"
      },
      {
         "value": 'America/Anchorage',
         "text": "(GMT -9:00) Alaska",
         "abbr": "AST"
      },
      {
         "value": 'America/Caracas',
         "text": "(GMT -4:00) Atlantic Time (Canada), Caracas, La Paz",
         "abbr": "AAAA"
      },
      {
         "value": 'Pacific/Kwajalein',
         "text": "(GMT -12:00) Pacific - Eniwetok, Kwajalein",
         "abbr": "AAAA"
      },
      {
        "value": 'Pacific/Midway',
        "text": "(GMT -11:00) Pacific - Midway Island, Samoa",
        "abbr": "AAAA"
      },
      {
         "value": 'America/St_Johns',
         "text": "(GMT -3:30) Canada - Newfoundland, Labrador",
         "abbr": "AAAA"
      },
      {
         "value": 'America/Buenos_Aires',
         "text": "(GMT -3:00) South America - Brazil, Buenos Aires, Georgetown",
         "abbr": "AAAA"
      },
      {
         "value": 'America/Noronha',
         "text": "(GMT -2:00) Mid-Atlantic",
         "abbr": "AAAA"
      },
      {
         "value": 'Atlantic/Azores',
         "text": "(GMT -1:00) Azores, Cape Verde Islands",
         "abbr": "AAAA"
      },
      {
         "value": 'Atlantic/Canary',
         "text": "(GMT) Western Europe Time, London, Lisbon, Casablanca",
         "abbr": "AAAA"
      },
      {
         "value": 'Europe/Paris',
         "text": "(GMT +1:00) Brussels, Copenhagen, Madrid, Paris",
         "abbr": "AAAA"
      },
      {
         "value": 'Europe/Kaliningrad',
         "text": "(GMT +2:00) Kaliningrad, South Africa",
         "abbr": "AAAA"
      },
      {
         "value": 'Asia/Baghdad',
         "text": "(GMT +3:00) Baghdad, Riyadh, Moscow, St. Petersburg",
         "abbr": "AAAA"
      },
      {
         "value": 'Asia/Tehran',
         "text": "(GMT +3:30) Tehran",
         "abbr": "AAAA"
      },
      {
         "value": 'Asia/Muscat',
         "text": "(GMT +4:00) Abu Dhabi, Muscat, Baku, Tbilisi",
         "abbr": "AAAA"
      },
      {
         "value": 'Asia/Kabul',
         "text": "(GMT +4:30) Kabul",
         "abbr": "AAAA"
      },
      {
         "value": 'Asia/Karachi',
         "text": "(GMT +5:00) Ekaterinburg, Islamabad, Karachi, Tashkent",
         "abbr": "AAAA"
      },
      {
         "value": 'Asia/Calcutta',
         "text": "(GMT +5:30) Bombay, Calcutta, Madras, New Delhi",
         "abbr": "AAAA"
      },
      {
         "value": 'Asia/Kathmandu',
         "text": "(GMT +5:45) Kathmandu",
         "abbr": "AAAA"
      },
      {
         "value": 'Asia/Almaty',
         "text": "(GMT +6:00) Almaty, Dhaka, Colombo",
         "abbr": "AAAA"
      },
      {
         "value": 'Asia/Bangkok',
         "text": "(GMT +7:00) Bangkok, Hanoi, Jakarta",
         "abbr": "AAAA"
      },
      {
         "value": 'Asia/Singapore',
         "text": "(GMT +8:00) Beijing, Perth, Singapore, Hong Kong",
         "abbr": "AAAA"
      },
      {
         "value": 'Asia/Tokyo',
         "text": "(GMT +9:00) Tokyo, Seoul, Osaka, Sapporo, Yakutsk",
         "abbr": "AAAA"
      },
      {
         "value": 'Australia/Adelaide',
         "text": "(GMT +9:30) Adelaide, Darwin",
         "abbr": "AAAA"
      },
      {
         "value": 'Pacific/Guam',
         "text": "(GMT +10:00) Eastern Australia, Guam, Vladivostok",
         "abbr": "AAAA"
      },
      {
         "value": 'Asia/Magadan',
         "text": "(GMT +11:00) Magadan, Solomon Islands, New Caledonia",
         "abbr": "AAAA"
      },
      {
         "value": 'Pacific/Fiji',
         "text": "(GMT +12:00) Auckland, Wellington, Fiji, Kamchatka",
         "abbr": "AAAA"
      }
   ]   
}